import React from "react"
import { graphql } from "gatsby"
// import SEO from "../components/seo"
import styled from '@emotion/styled'
import Body from "../components/Body"

const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `20rem`
})

const Header = styled(`header`)({
  marginBottom: `1rem`
})

const InfoTemplate = ({ data, location }) => {
  const { info } = data
  return (
    <React.Fragment>
      {/*<SEO title="All events" />*/}
      <Article>
        { !info.hideTitle &&
          <Header>
            <h3>{info.title}</h3>
          </Header>
        }
        <section>
          <Body value={info._rawBody} />
        </section>
      </Article>
    </React.Fragment>
  )
}

export default InfoTemplate

export const pageQuery = graphql`
  query InfoBySlug($id: String!) {
    info: sanityInfo(id: { eq: $id }) {
      id
      title
      hideTitle
      _rawBody(resolveReferences: {maxDepth: 10})
    }
  }
`
